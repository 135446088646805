import { useReport } from "~/composables/useReport";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";

async function doSignOut() {
  try {
    const userStore = (await import("~/stores/user")).default;
    const useUserStore = userStore();
    const {
      storageRemoveAccessToken,
      storageRemoveAccessTokenExpiredIn,
      storageRemoveRefreshToken,
    } = await import("~/composables/accessToken");
    storageRemoveAccessToken();
    storageRemoveRefreshToken();
    storageRemoveAccessTokenExpiredIn();
    useUserStore.resetState();
  } catch (err) {
    useReport({
      level: "error",
      message: `${DEFAULT_ERROR_MESSAGE}, failed to logout`,
      errorException: err,
    });
  }
}

export { doSignOut };
